<!-- 注册邀请-网站介绍 -->
<template>
  <div class="dip bgBox">
    <div class="conBox flex jcenter">
      <div class="fontBox">
        <div class="fontBoxH3">
          {{ $fanyi("我们是你通往中国的桥梁") }}
        </div>
        <div class="fontBoxH2">
          {{ $fanyi("触手可及的世界上最大的时尚市场") }}
        </div>
        <div class="fontBoxCon">
          {{
            $fanyi(
              "Rakumart是B2B一体化导入平台。我们为您管理从中国采购、物流和进口的整个过程。我们广泛的合作伙伴网络为您提供了广泛的产品选择和超过100,000家供应商，我们每天与他们合作。一项旨在帮助您推动业务发展的服务。"
            )
          }}
        </div>
        <div class="dip">
          <el-button
            type="primary"
            class="bgBlueBtn btnCss"
            @click="$refs.BeginnerTipsDia.open()"
            ><img
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670e290ef29a6.svg"
              alt=""
            />{{ $fanyi("看视频") }}</el-button
          >
          <button
            style="margin-left: 10px;"
            class="fontBlueBtn btnCss"
            @click="$store.commit('getshowRegisterDialog', true)"
          >
            {{ $fanyi("免费注册") }}
          </button>
        </div>
      </div>
      <div class="imgBox">
        <img
          src=" https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670e0aec2810a.png"
          alt=""
        />
      </div>
    </div>
    <beginnerTipsDia ref="BeginnerTipsDia" />
  </div>
</template>
<script>
import beginnerTipsDia from "./dialog-beginnerTipsDia";
export default {
  data() {
    return {};
  },
  components: {
    beginnerTipsDia,
  },
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.bgBox {
  background-image: url("https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670e0af18b219.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: white;
}
.conBox {
  height: 835px;

  .fontBox {
    width: 724px;
    margin-right: 16px;
    padding: 24px 0;
    @extend .dip;
    flex-direction: column;
    align-items: flex-start;
    .fontBoxH3 {
      color: #1a73e8;
      font-size: 20px;
      font-weight: 700;
      line-height: 30px; /* 150% */
      margin-bottom: 8px;
    }
    .fontBoxH2 {
      font-size: 48px;
      font-weight: 600;
      line-height: 60px; /* 125% */
      letter-spacing: -0.96px;
      margin-bottom: 24px;
      color: var(--Gray-950, #0c111d);
    }
    .fontBoxCon {
      font-size: 18px;
      line-height: 28px; /* 155.556% */
      margin-bottom: 40px;
      color: #475467;
    }
    .btnCss {
      width: 186px;
      height: 48px;
      font-size: 16px;
      /deep/ span {
        @extend .dip;
        img {
          margin-right: 6px;
        }
      }
    }
  }
  .imgBox {
    width: 925px;
    img {
      width: 100%;
    }
  }
}
</style>
