<!-- 邀请注册-已注册用户 -->
<template>
  <div class="conBox">
    <div class="headBox dip fdcolmun">
      <div class="headBoxH3">{{ $fanyi("好进口商俱乐部") }}</div>
      <div class="headBoxH2">{{ $fanyi("超过10,000个注册用户") }}</div>
      <div class="headBoxCon">
        {{
          $fanyi("我们的社区每天都在增长。加入它，享受与Rakumart进口的好处。")
        }}
      </div>
    </div>

    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide userItemBox flex"
          style="border: none;flex:0 0 200px;"
        ></div>
        <div class="swiper-slide userItemBox flex">
          <div class="videoBox">
            <img
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670f4d5e54721.png"
              alt=""
              v-if="!loadVideo[0]"
              @click="$set(loadVideo, 0, true)"
            />
            <video
              @pause="onVideoPause(0, 0)"
              @play="onVideoPlay(0, 0)"
              @ended="onVideoEnded(0, 0)"
              autoplay
              controls
              controlsList="nodownload"
              v-else
            >
              <source
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670f4dbdb58cf.mp4"
                type="video/mp4"
              />

              您的浏览器不支持 video 标签。
            </video>
          </div>

          <div class="fontBox">
            <div class="fontCon">
              {{
                $fanyi(
                  "“Rakumart为你提供了很多便利，你可以把所有东西都放在家门口，而不必担心任何你不知道如何管理的事情。”"
                )
              }}
            </div>
            <div class="fontBy" style="font-weight: 600;">
              Maria José
            </div>
            <div class="fontBy">
              {{ $fanyi("亚马逊卖家") }}
            </div>
          </div>
        </div>

        <div class="swiper-slide userItemBox flex">
          <div class="videoBox">
            <img
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670f4d8cc896d.png"
              alt=""
              v-if="!loadVideo[1]"
              @click="$set(loadVideo, 1, true)"
            />
            <video
              @pause="onVideoPause(0, 1)"
              @play="onVideoPlay(0, 1)"
              @ended="onVideoEnded(0, 1)"
              autoplay
              controls
              controlsList="nodownload"
              v-else
            >
              <source
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670f4e40abc27.mp4"
                type="video/mp4"
              />

              您的浏览器不支持 video 标签。
            </video>
          </div>

          <div class="fontBox">
            <div class="fontCon">
              {{
                $fanyi(
                  "“你省去了很多麻烦，可以把时间花在你真正感兴趣的业务上，而不是花在官僚程序上。”"
                )
              }}
            </div>
            <div class="fontBy" style="font-weight: 600;">
              Mireia
            </div>
            <div class="fontBy">
              {{ $fanyi("亚马逊卖家") }}
            </div>
          </div>
        </div>

        <div class="swiper-slide userItemBox flex">
          <div class="videoBox">
            <img
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670f4d94c713c.png"
              alt=""
              v-if="!loadVideo[2]"
              @click="$set(loadVideo, 2, true)"
            />
            <video
              @pause="onVideoPause(2, 2)"
              @play="onVideoPlay(2, 2)"
              @ended="onVideoEnded(2, 2)"
              autoplay
              controls
              controlsList="nodownload"
              v-else
            >
              <source
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670f4ba4bbe0c.mp4"
                type="video/mp4"
              />

              您的浏览器不支持 video 标签。
            </video>
          </div>

          <div class="fontBox">
            <div class="fontCon">
              {{ $fanyi("“我对你们的客户服务和待遇非常满意。可取的。”") }}
            </div>
            <div class="fontBy" style="font-weight: 600;">
              Fernando
            </div>
            <div class="fontBy">
              {{ $fanyi("亚马逊卖家") }}
            </div>
          </div>
        </div>

        <div class="swiper-slide userItemBox flex">
          <div class="videoBox">
            <img
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670f4d9a2af78.png"
              alt=""
              v-if="!loadVideo[3]"
              @click="$set(loadVideo, 3, true)"
            />
            <video
              @pause="onVideoPause(3, 3)"
              @play="onVideoPlay(3, 3)"
              @ended="onVideoEnded(3, 3)"
              autoplay
              controls
              controlsList="nodownload"
              v-else
            >
              <source
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670f561d74e10.mp4"
                type="video/mp4"
              />

              您的浏览器不支持 video 标签。
            </video>
          </div>

          <div class="fontBox">
            <div class="fontCon">
              {{
                $fanyi(
                  "“Rakumart将整个导入过程打包在一个包中，整个过程都是西班牙语的，这使得沟通更加流畅和容易。”"
                )
              }}
            </div>
            <div class="fontBy" style="font-weight: 600;">
              Iraki
            </div>
            <div class="fontBy">
              {{ $fanyi("亚马逊卖家") }}
            </div>
          </div>
        </div>

        <div class="swiper-slide userItemBox flex">
          <div class="videoBox">
            <img
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670f4d9f38eab.png"
              alt=""
              v-if="!loadVideo[4]"
              @click="$set(loadVideo, 4, true)"
            />
            <video
              @pause="onVideoPause(4, 4)"
              @play="onVideoPlay(4, 4)"
              @ended="onVideoEnded(4, 4)"
              autoplay
              controls
              controlsList="nodownload"
              v-else
            >
              <source
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670f4b5a9cd88.mp4"
                type="video/mp4"
              />

              您的浏览器不支持 video 标签。
            </video>
          </div>

          <div class="fontBox">
            <div class="fontCon">
              {{
                $fanyi(
                  "“它大大简化了从中国进口到这里的过程，也简化了商品的标签和包装。”"
                )
              }}
            </div>
            <div class="fontBy" style="font-weight: 600;">
              Efrain
            </div>
            <div class="fontBy">
              {{ $fanyi("亚马逊卖家") }}
            </div>
          </div>
        </div>

        <div class="swiper-slide userItemBox flex">
          <div class="videoBox">
            <img
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670f4da4329d8.png"
              alt=""
              v-if="!loadVideo[5]"
              @click="$set(loadVideo, 5, true)"
            />
            <video
              @pause="onVideoPause(4, 5)"
              @play="onVideoPlay(4, 5)"
              @ended="onVideoEnded(4, 5)"
              autoplay
              controls
              controlsList="nodownload"
              v-else
            >
              <source
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670f53b773978.mp4"
                type="video/mp4"
              />

              您的浏览器不支持 video 标签。
            </video>
          </div>

          <div class="fontBox">
            <div class="fontCon">
              {{
                $fanyi(
                  "“他们从头到尾都在指导你。这让事情变得更容易。它让你更容易专注于你的业务。”"
                )
              }}
            </div>
            <div class="fontBy" style="font-weight: 600;">
              Xavi
            </div>
            <div class="fontBy">
              {{ $fanyi("亚马逊卖家") }}
            </div>
          </div>
        </div>

        <div
          class="swiper-slide userItemBox flex"
          style="border: none;flex:0 0 200px;"
        ></div>
      </div>
      <div class="swiper-pagination"></div>
      <div class="swiper-button-next">
        <img
          src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/67121433d2b13.svg"
          alt=""
        />
      </div>
      <div class="swiper-button-prev">
        <img
          src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/67121433d2b13.svg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
export default {
  data() {
    return {
      loadVideo: [false, false, false, false, false, false],
      playVideo: [false, false, false, false, false, false],
      active: 0,
      swiper: null,
    };
  },
  components: {},
  computed: {},
  created() {},
  watch: {},
  mounted() {
    this.getSwiper();
  },
  methods: {
    getSwiper() {
      this.swiper = new Swiper(".swiper-container", {
        loop: false,
        // autoplay: {
        // //自动开始
        //  delay: 4000, //时间间隔
        //  disableOnInteraction: false, //*手动操作轮播图后不会暂停*
        // },
        slidesPerView: "auto",
        // centeredSlides: true, //居中显示
        // slidesPerView: 4, // 一组三个
        spaceBetween: 24, // 间隔
        // 窗口变化,重新init,针对F11全屏和放大缩小,必须加
        observer: true,
        observeParents: true,
        // 如果需要分页器
        pagination: {
          el: ".swiper-pagination",
          clickable: true, // 分页器可以点击
        },
        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
    onVideoEnded(weizhi, index) {
      console.log("播放结束");

      this.playVideo[index] = false;
      let flog = this.playVideo.some((ii) => ii == true);
      if (!flog) {
        this.swiper.autoplay.start();
      }
    },
    onVideoPlay(weizhi, index) {
      console.log("视频开始播放");
      this.swiper.autoplay.stop();
      this.swiper.slideTo(weizhi);
      this.playVideo[index] = true;
      this.swiper.centeredSlides = true;
    },
    onVideoPause(weizhi, index) {
      console.log("视频暂停播放");
      this.playVideo[index] = false;
      let flog = this.playVideo.some((ii) => ii == true);
      if (!flog) {
        this.swiper.autoplay.start();
      }
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.conBox {
  margin: 0 auto;
  padding: 115px 0;
  .headBox {
    text-align: center;
    margin: 0 auto 38px;
    width: 820px;
    .headBoxH3 {
      color: var(--Rakumart-500, #1a73e8);
      font-size: 20px;
      font-weight: 700;
      line-height: 30px; /* 150% */
      margin-bottom: 8px;
    }
    .headBoxH2 {
      font-size: 48px;
      font-weight: 600;
      line-height: 60px; /* 125% */
      letter-spacing: -0.96px;
      margin-bottom: 16px;
      color: var(--Gray-950, #0c111d);
    }
    .headBoxCon {
      width: 660px;
      font-size: 18px;
      line-height: 28px; /* 150% */
    }
  }
}

.program {
  position: relative;
  margin: 30px auto;
  width: 400px;
  height: 400px;
  text-align: center;
  border: 1px solid #000;
  .middle-box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100px;
    height: 100px;
    background-color: rgb(255, 160, 93);
    .small-box {
      position: absolute;
      left: 0;
      top: 0;
      width: 50px;
      height: 50px;
      background-color: #646464;
    }
  }
}

.swiper-container {
  padding: 32px 0px 114px;
}

.swiper-slide {
  position: relative;
  width: 264px;
  padding: 0px;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #f7f8fa;

  .el-button {
    z-index: 2;
  }
}

.swiper-pagination {
  bottom: 32px;
  /deep/.swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background-color: #eaecf0;
    opacity: 1;
  }

  /deep/.swiper-pagination-bullet-active {
    background-color: #1355ab;
  }
}

.userItemBox {
  width: 785px;
  flex: 0 0 785px;
  overflow: hidden;
  background-color: white;
  border-radius: 16px;
  border: 2px solid var(--Gray-200, #eaecf0);
  margin-right: 24px;
  /* Shadows/shadow-lg */
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  .videoBox {
    flex: 0 0 266px;
    height: 300px;
    overflow: hidden;
    margin-right: 40px;
    img,
    video {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  .fontBox {
    user-select: none;
    @extend .dip;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 60px;
    .fontCon {
      margin-bottom: 16px;
      font-size: 20px;
      line-height: 30px; /* 150% */
      color: var(--Gray-950, #0c111d);
    }
    .fontBy {
      color: var(--Gray-500, #667085);
      font-size: 16px;
      line-height: 24px; /* 150% */
    }
  }
}

.swiper-button-next,
.swiper-button-prev {
  align-items: center;
  cursor: pointer;
  transform: unset !important;
  top: 25px !important;
  height: 360px;
  background-color: rgba($color: #000000, $alpha: 1);
  width: 20%;
  justify-content: flex-end;
  padding-right: 5%;
  &:after {
    display: none;
  }
}

.swiper-button-next {
  right: 0px;
  background: linear-gradient(270deg, #f6f6f6 0%, rgba(246, 246, 246, 0) 100%);
}

.swiper-button-prev {
  rotate: 180deg;
  left: 0px;
  background: linear-gradient(270deg, #f6f6f6 0%, rgba(246, 246, 246, 0) 100%);
}

.swiper-button-disabled {
  opacity: 1;
}
</style>
