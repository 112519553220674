<!-- 注册邀请-网站优势 -->
<template>
  <div class="dip bgBox">
    <div class="conBox">
      <div class="fontBox">
        <div class="conH3">{{ $fanyi("便利从中国进口") }}</div>
        <div class="conH2">{{ $fanyi("找到你想要的一切") }}</div>
        <div class="conCon">
          {{ $fanyi("它受益于从中国进口的好处，而没有随之而来的复杂性。") }}
        </div>
      </div>

      <div class="keyAdvantagesBox">
        <div class="keyAdvantagesItem">
          <div class="headIconBox">
            <img
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670e1521bfbfb.svg"
              alt=""
            />
          </div>
          <div class="keyAdvantagesItemH2">
            {{ $fanyi("具有经过验证的供应商的无限目录") }}
          </div>
          <div class="keyAdvantagesItemCon">
            {{ $fanyi("进入世界上最大的批发市场，发现它提供的所有机会。") }}
          </div>
        </div>

        <div class="keyAdvantagesItem">
          <div class="headIconBox">
            <img
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670e1524dd6ad.svg"
              alt=""
            />
          </div>
          <div class="keyAdvantagesItemH2">
            {{ $fanyi("与中国直接联系") }}
          </div>
          <div class="keyAdvantagesItemCon">
            {{
              $fanyi(
                "在这个过程的每个阶段控制你的货物。我们在中国和西班牙的基础设施使进口的实时跟踪成为可能。"
              )
            }}
          </div>
        </div>

        <div class="keyAdvantagesItem">
          <div class="headIconBox">
            <img
              src=" https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670e15275f562.svg"
              alt=""
            />
          </div>
          <div class="keyAdvantagesItemH2">
            {{ $fanyi("个性化客户服务") }}
          </div>
          <div class="keyAdvantagesItemCon">
            {{
              $fanyi(
                "我们的专家团队将用西班牙语回答您的所有问题，并指导您完成进口的每一步。"
              )
            }}
          </div>
        </div>

        <div class="keyAdvantagesItem">
          <div class="headIconBox">
            <img
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/670e1529c3ffb.svg"
              alt=""
            />
          </div>
          <div class="keyAdvantagesItemH2">
            {{ $fanyi("定制的商业体验") }}
          </div>
          <div class="keyAdvantagesItemCon">
            {{
              $fanyi(
                "定制您从中国进口的产品。选择您需要的产品，并根据您的具体需求定制服务。"
              )
            }}
          </div>
        </div>
      </div>

      <div class="dip">
        <el-button
          type="primary"
          class="bgBlueBtn"
          @click="$store.commit('getshowRegisterDialog', true)"
          >{{ $fanyi("免费注册") }}</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  components: {},
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.bgBox {
  background: var(--Gray-50, #f9fafb);
}
.conBox {
  padding: 80px 0;
  .fontBox {
    width: 660px;
    text-align: center;
    margin: 0 auto 48px;
    .conH3 {
      color: var(--Rakumart-500, #1a73e8);
      font-size: 20px;
      font-weight: 700;
      line-height: 30px; /* 150% */
    }
    .conH2 {
      font-size: 48px;
      font-weight: 600;
      line-height: 60px; /* 125% */
      letter-spacing: -0.96px;
      margin-bottom: 16px;
      color: var(--Gray-950, #0c111d);
    }
    .conCon {
      font-size: 20px;
      line-height: 30px; /* 150% */
    }
  }
  .keyAdvantagesBox {
    @extend .dip;
    justify-content: space-between;
    gap: 32px;
    margin-bottom: 48px;
    .keyAdvantagesItem {
      padding: 32px;
      width: 344px;
      min-height: 406px;
      border-radius: 16px;
      border: 2px solid var(--Gray-200, #eaecf0);
      background: #fff;
      .headIconBox {
        img {
          width: 56px;
        }
        margin-bottom: 16px;
      }

      .keyAdvantagesItemH2 {
        font-size: 30px;
        font-weight: 700;
        line-height: 38px; /* 126.667% */
        margin-bottom: 16px;
        color: var(--Gray-950, #0c111d);
      }

      .keyAdvantagesItemCon {
        font-size: 18px;
        line-height: 28px; /* 155.556% */
      }
    }
  }

  .bgBlueBtn {
    width: 320px;
    height: 60px;
    font-size: 18px;
    font-weight: 700;
  }
}
</style>
