<!-- 邀请注册 -->
<template>
  <div class="">
    <!-- 头部 -->
    <opageHead />
    <!-- 网站介绍 -->
    <websiteIntroduction />
    <!-- 网站优势 -->
    <websiteAdvantages />
    <!-- 热门店铺 -->
    <popularStores />
    <!-- 如何运作 -->
    <howToOperate />
    <!-- 已注册用户 -->
    <registeredUser />
    <!-- 准备好了吗 -->
    <areYouReady />
    <!-- 常见问题 -->
    <commonProblem />
    <!-- 底部 -->
    <registerFoot />
  </div>
</template>
<script>
import opageHead from "./components/model-pageHead.vue";
import websiteIntroduction from "./components/model-websiteIntroduction.vue";
import websiteAdvantages from "./components/model-websiteAdvantages.vue";
import popularStores from "./components/model-popularStores.vue";
import howToOperate from "./components/model-howToOperate.vue";
import registeredUser from "./components/model-registeredUser.vue";
import areYouReady from "./components/model-areYouReady.vue";
import commonProblem from "./components/model-commonProblem.vue";
import registerFoot from "./components/model-registerFoot.vue";

export default {
  data() {
    return {};
  },
  components: {
    opageHead,
    websiteIntroduction,
    websiteAdvantages,
    popularStores,
    howToOperate,
    registeredUser,
    areYouReady,
    commonProblem,
    registerFoot,
  },
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
* {
  color: #344054;
}
</style>
