import { render, staticRenderFns } from "./model-popularStores.vue?vue&type=template&id=624634c1&scoped=true&"
import script from "./model-popularStores.vue?vue&type=script&lang=js&"
export * from "./model-popularStores.vue?vue&type=script&lang=js&"
import style0 from "./model-popularStores.vue?vue&type=style&index=0&id=624634c1&lang=scss&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "624634c1",
  null
  
)

export default component.exports