import { render, staticRenderFns } from "./model-websiteIntroduction.vue?vue&type=template&id=818b1788&scoped=true&"
import script from "./model-websiteIntroduction.vue?vue&type=script&lang=js&"
export * from "./model-websiteIntroduction.vue?vue&type=script&lang=js&"
import style0 from "./model-websiteIntroduction.vue?vue&type=style&index=0&id=818b1788&lang=scss&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "818b1788",
  null
  
)

export default component.exports