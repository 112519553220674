<template>
  <div class="conBox">
    <div class="backgrBox dip">
      <div class="dip fdcolmun fontConBox">
        <div class="smallTitle">
          {{ $fanyi("用Rakumart推动你的业务") }}
        </div>
        <h2>{{ $fanyi("你准备好开始导入了吗?") }}</h2>
        <p>
          {{ $fanyi("现在注册，成为重要社区的一部分。") }}
        </p>
        <el-button
          class="fontBlueBtn register"
          @click="$store.commit('getshowRegisterDialog', true)"
          >{{ $fanyi("免费注册") }}</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: !localStorage.getItem("user_token"),
    };
  },
  components: {},
  computed: {},
  watch: {
    "$store.state.userInfo"(onew, old) {
      console.log(!!onew);
      if (!onew) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.conBox {
  background-image: url("https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/67106b117b04f.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  height: 520px;
  .backgrBox {
    height: 100%;

    background-image: linear-gradient(
      0deg,
      rgba(26, 115, 232, 0.75) 0%,
      rgba(26, 115, 232, 0.75) 100%
    );
    color: var(--Base-White, #fff);

    .fontConBox {
      text-align: center;
      .smallTitle {
        font-size: 20px;
        font-weight: 700;
        line-height: 30px; /* 150% */
        margin-bottom: 20px;
      }
      h2 {
        color: var(--Base-White, #fff);
        font-size: 48px;
        font-weight: 700;
        line-height: 60px; /* 125% */
        letter-spacing: -0.96px;
        margin-bottom: 20px;
        width: 900px;
      }
      p {
        font-size: 20px;
        line-height: 30px; /* 150% */
        margin-bottom: 32px;
      }
      .register {
        width: 292px;
        height: 60px;
        padding: 16px 22px;
        font-size: 18px;
        font-weight: 700;
      }
    }
  }
}
</style>
