<template>
  <div class="footerBackground">
    <div class="flex jsb acenter" style="margin-bottom:32px">
      <img
        :src="require('@/assets/hometop/logo-rakumart-europe.svg')"
        alt=""
        style="width:200px"
      />

      <div class="socialNetworkingPlatform">
        <!-- facebook -->
        <a
          href="https://www.facebook.com/people/Rakumartes/100086130939868/"
          target="_blank"
        >
          <img
            src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202409/66e00e619313c.svg"
            alt=""
          />
        </a>
        <!-- instagram -->
        <a href="https://www.instagram.com/rakumart.es/" target="_blank">
          <img
            src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202409/66e00e725c95f.svg"
            alt=""
          />
        </a>
        <!-- tik-tok -->
        <a href="https://www.tiktok.com/@rakumart" target="_blank"
          ><img
            src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202409/66e00e7ff20a5.svg"
            alt=""
        /></a>
        <!-- youtube -->
        <a href="https://www.youtube.com/@rakumartespana" target="_blank"
          ><img
            src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202409/66e00e89b4581.svg"
            alt=""
        /></a>
        <!-- linkedin -->
        <a
          href="https://www.linkedin.com/company/rakumart-espa%C3%B1a/"
          target="_blank"
          ><img
            src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202409/66e00e92c2d1c.svg"
            alt=""
        /></a>
      </div>
    </div>
    <div class="hr"></div>
    <div class="bottomBox flex jsb">
      <div>
        <p>
          ©Rakumart 2023 Todos los derechos reservados - Elosung Europe S.A.
          Carrer de Francesc Teixidó, 43, 08918 Badalona, Barcelona, España
        </p>
      </div>
      <div class="flex jsb otherBox">
        <p @click="$fun.routerToPage('/condiciones-generales-de-contratacion')">
          {{ $fanyi("一般条件") }}
        </p>
        <p @click="$fun.routerToPage('/aviso-legal')">
          {{ $fanyi("法律通知") }}
        </p>
        <p @click="$fun.routerToPage('/politica-privacidad')">
          {{ $fanyi("用户协议和隐私政策") }}
        </p>
        <p @click="$fun.routerToPage('/politica-de-cookies')">
          {{ $fanyi("Cookie 政策") }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      islogin: false,
    };
  },
  created() {
    if (this.$route.path == "/login") {
      this.islogin = false;
    } else {
      this.islogin = true;
    }
  },
  methods: {
    openUserCenter() {
      if (localStorage.getItem("user_token")) {
        this.$fun.routerToPage("/user/index");
      } else {
        this.$store.commit("getactivePage", "/user/index");
        this.$store.commit("getshowLoginDialog", true); //打开登录弹窗
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/css/mixin.scss";

.footerBackground {
  width: max-content;
  width: 100%;
  background-color: #ffffff;
  border-top: solid 1px #e8e8e8;
  padding: 64px 72px 48px;
  .socialNetworkingPlatform {
    img {
      margin-right: 16px;
      width: 24px;
      height: 24px;
    }
  }
  .boxOpt {
    height: 200px;
    margin-left: 32px;
    .boxTitle {
      color: #667085;
      margin-bottom: 16px;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px; /* 142.857% */
    }
    .boxSpan {
      margin-bottom: 12px;
      color: #101828;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px; /* 150% */
      cursor: pointer;
    }
  }
  .hr {
    background-color: #eaecf0;
    height: 1px;
    margin-bottom: 32px;
  }
  .bottomBox {
    h1 {
      color: var(--Gray-900, #101828);
      /* Text xl/Bold */
      font-family: "Open Sans";
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px; /* 150% */
      margin-bottom: 8px;
    }
    h2 {
      color: var(--Gray-600, #475467);

      /* Text md/Regular */
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
    p {
      color: var(--Gray-500, #667085);
      text-align: right;

      /* Text md/Regular */
      font-family: "Open Sans";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
    .otherBox {
      gap: 26px;
      p {
        cursor: pointer;
      }
    }
  }
}
</style>
